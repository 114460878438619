import React from "react";

export const Products = (props) => {
  return (
    <div id="products" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Products</h2>
          <div className="row">
            <h3>{props.data ? props.data.title : "loading..."} </h3>
            <h3>{props.data ? props.data.information : "loading..."}</h3>
            <p>{props.data ? props.data.paragraph : "loading..."}</p>
            <br></br>
            <h3>Why GGBS?</h3>
            <div className="list-style">
              <ul>
                {props.data
                  ? props.data.Why.map((d, i) => <li key={`${d}-${i}`}>{i+1}. {d}</li>)
                  : "loading"}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
